<template>
  <div class="menuBox">
    <div class="content">
      <div class="logoBox">
        <template v-if="pageHeaderLogo && pageHeaderLogo.navigationLogoUrl">
          <img
            class="pageHeaderLogo"
            :src="pageHeaderLogo.navigationLogoUrl"
            alt=""
          />
        </template>
        <template v-else>
          <img
            class="pageHeaderLogo"
            src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/new-saas/PC/home/header_logo.png"
            alt=""
          />
          <!-- <span class="name">伟东云SAAS平台</span> -->
        </template>
      </div>
      <el-menu
        :mode="'horizontal'"
        active-text-color="#245FFF"
        text-color="#767575"
        :default-active="activeNav"
        style="width: auto"
        @select="handleSelect"
      >
        <template v-for="item in navList">
          <template>
            <el-menu-item :index="item.url" :key="item.url">
              <span slot="title" class="name" :title="item.name">{{
                item.name
              }}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
      <div class="userInfo-right">
        <div class="course-search-box" v-if="$route.path === '/course/list'">
          <p class="label">课程</p>
          <el-input
            v-model="courseSearchName"
            placeholder="请输入内容"
          ></el-input>
          <p class="btn" @click="courseSearch">搜索</p>
        </div>
        <template v-if="token">
          <el-dropdown trigger="click" class="user-dropdown">
            <span class="el-dropdown-link" @click="showUser = !showUser">
              <img
                class="headImage"
                :src="
                  subUserInfo?.headImage
                    ? subUserInfo.headImage
                    : require('../../../../assets/images/userL.png')
                "
                alt=""
              />
              <span>{{ subUserInfo?.name || subUserInfo?.nickname }}</span>
            </span>
            <el-dropdown-menu
              :append-to-body="false"
              slot="dropdown"
              class="account-dropdown-menu"
            >
              <div class="top">
                <img
                  class="headImage"
                  :src="
                    subUserInfo?.headImage ||
                    require('../../../../assets/images/userL.png')
                  "
                  alt=""
                />
                <p class="personal" @click="jump">个人中心</p>
                <p class="currentCorporation">
                  当前企业：{{ UserBranchList?.name }}
                </p>
                <p class="adminBtn" v-if="isAdmin" @click="goAdmin">
                  切换到管理后台
                </p>
                <p @click="concatus">联系我们</p>
              </div>
              <p class="line"></p>
              <div class="bottom-out" @click="dialogVisible.show = true">
                退出登录
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </div>
    </div>
    <el-dialog
      title="联系我们"
      :visible.sync="concatVisible"
      width="360px"
       :modal-append-to-body="false"
      :before-close="handleClose">
      <div class="concat-box">
        <p>
            <span>联系方式：</span>
            <span>4008-555-686</span>
        </p>
      </div>
    </el-dialog>
    <logOutDialog :dialogVisible="dialogVisible"></logOutDialog>
  </div>
</template>

<script>
import logOutDialog from "./logOutDialog";
import URL from "../../../../config/url";
export default {
  components: { logOutDialog },
  computed: {
    subUserInfo() {
      return this.$store.state.subUserInfo;
    },
  },
  data() {
    return {
      concatVisible:false,
      dialogVisible: {
        show: false,
      },
      showUser: false,
      token: "",
      courseSearchName: "",
      UserBranchList:
        (localStorage.getItem("UserBranchList") &&
          JSON.parse(localStorage.getItem("UserBranchList"))[0]) ||
        null,
      activeNav: "/" + this.$route.path.split("/")[1],
      navList: [
        {
          name: "首页",
          id: 1,
          url: "/",
        },
        {
          name: "课程中心",
          id: 2,
          url: "/course/list",
        },
        {
          name: "讲师",
          id: 4,
          url: "/lecturer/list",
        },
        {
          name: "我的学习",
          id: 3,
          url: "/learn/list",
        },
      ],
      isAdmin: false,
      pageHeaderLogo: null,
    };
  },
  watch: {
    $route() {
      sessionStorage.setItem("index", this.$route.meta.highlight);
      if (this.courseSearchName) {
        this.courseSearchName = "";
      }
      setTimeout(() => {
        //路由跳转
        this.activeNav = this.$route.meta.highlight;
        console.log(this.activeNav,'watchwatchwatchwatchwatchwatchwatchwatchwatchwatch');
      }, 100);
    },
  },
  created() {
    let setTempConfigs =
      localStorage.getItem("setTempConfigs") &&
      JSON.parse(localStorage.getItem("setTempConfigs"));
    console.log(setTempConfigs, "setTempConfigs===");
    this.pageHeaderLogo =
      setTempConfigs.pageHeader && JSON.parse(setTempConfigs.pageHeader);
    this.token = localStorage.getItem("token");
    console.log(this.activeNav, "activeNav");

    this.token && this.getIsAdmin();
  },
  async mounted() {
    setTimeout(() => {
      //刷新
      if(this.$route.path=='/learn/list' && sessionStorage.getItem("index")=='/course/list'){
        this.activeNav = '/learn/list';
      }else{
        this.activeNav = sessionStorage.getItem("index") || this.activeNav;
      }
      console.log(this.activeNav,'mountedmountedmountedmountedmountedmountedmountedmounted');
    }, 100);
  },
  methods: {
    handleClose(){
      this.concatVisible = false;
    },
    concatus(){
      this.concatVisible = true;
    },
    getIsAdmin() {
      this.$api.usercenter.getIsAdmin().then((res) => {
        this.isAdmin = res.data || false;
      });
    },
    courseSearch() {
      this.$store.commit("courseCenterSearchName", this.courseSearchName);
    },
    goAdmin() {
      window.open(
        URL.env
          ? `https://jj-main.${URL.env}.wdeduc.com`
          : "https://jj-main.wdeduc.com"
      );
    },
    jump() {
      this.$router.push("/personal");
      this.showUser = false;
    },
    handleSelect(keyPath) {
      if (keyPath === this.$route.path) {
        return false;
      }
      if (this.token) {
        this.$router.push(keyPath);
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="scss" src="../../../../assets/css/menu.scss" scoped></style>
<style lang="scss" scoped>
.concat-box{
  padding-left: 36px;
  p{
    line-height: 36px;
    font-size: 16px;
    span:nth-child(2){
      color:#1a72ff;
    }
  }
}
</style>