<template>
  <div class="courseList">
    <div class="box">
      <div class="right-ranking">
        <div class="titleBox">
          <div class="lecturerImg">
            <img
              :src="
                lecturerData.avatar
                  ? lecturerData.avatar
                  : 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/lecturerIcon.png'
              "
              class="titleImg"
              alt=""
            />
          </div>
          <div class="lecturer-name">
            <p class="name">{{ lecturerData.name }}</p>
            <img
              :src="
                lecturerData.gender === 1
                  ? 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/male.png'
                  : 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-h5/female.png'
              "
              class="cover"
              alt=""
            />
          </div>
          <p class="title">{{ lecturerData.teacherLevelName }}</p>
          <div class="classNum">
            <div class="number">{{ lecturerData.courseSize }}</div>
            <div class="des">课程数量</div>
          </div>
          <div class="starts">
            <div class="img" v-for="(item, i) in 5" :key="i">
              <img
                :src="
                  i < lecturerData?.teacherEvaluateDto?.evaluateScore
                    ? startActive
                    : start
                "
                alt=""
              />
            </div>
            <div class="goSource" @click="goSource()">
              <p class="word">去评分</p>
              <div class="source">
                <img src="../assets/img/right.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="personal">
          <div class="title">个人介绍</div>
          <div class="dex">
            {{ lecturerData.remark ? lecturerData.remark : "暂无介绍" }}
          </div>
        </div>
      </div>
      <div class="lecturer-content">
        <div class="listContainer">
          <div class="lecturer-list">
            <div class="listBox">
              <div
                class="listItem"
                v-for="item in AllCourseList"
                :key="item.ucode"
                @click="handleDetail(item)"
              >
                <img :src="item.coverImage" class="cover" alt="" />
                <div class="info">
                  <p class="name" :title="item.name">{{ item.name }}</p>
                  <div class="bottom">
                    <p class="class">{{ item.categoryName }}</p>
                    <p class="num">{{ item.taskUserCount }}人报名</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <article v-if="noData" class="ListEmptyData">
          <img src="../../../assets/images/course-icon.png" alt />
          <p>没有找到任何课程</p>
        </article>
        <div v-if="!noData" class="pagination">
          <p class="total">共{{ total }}个课程</p>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="pageNum"
            :page-size="pageSize"
            layout="prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      class="alertDialog"
      :visible.sync="sourceDialogVisible"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="bg">
        <img src="../assets/img/dialogBg.png" alt="" />
      </div>
      <div class="dialogCon">
        <div class="score">
          <p>{{ score }}</p>
          分
        </div>
        <div class="starts">
          <div
            class="img"
            v-for="(item, i) in 5"
            :key="i"
            @click="updateScore(i)"
          >
            <img :src="i < score ? startActive : start" alt="" />
          </div>
        </div>
        <div class="des">{{ dialogDes[score - 1] }}</div>
        <div class="dialog-footer">
          <el-button class="cancel" @click="sourceDialogVisible = false"
            >取消</el-button
          >
          <el-button class="submit" type="primary" @click="saveScore()"
            >提交</el-button
          >
        </div>
      </div>

      <!-- <button class="btn" @click="">我在，继续学习</button> -->
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      noData: null,
      total: 0,
      pageNum: 1,
      pageSize: 12,
      AllCourseList: [],
      lecturerData: {},
      sourceDialogVisible: false,
      score: 0,
      startActive: require("../assets/img/startActive.png"),
      start: require("../assets/img/start.png"),
      dialogDes: [
        "营养不足～",
        "有点营养～",
        "养分很多～",
        "养分充足～",
        "干货满满～",
      ],
    };
  },
  created() {
    this.getCourseListByUcode();
    this.getLectureInfo();
  },
  computed: {},
  watch: {},
  methods: {
    updateScore(i) {
      console.log("111111111", i + 1);
      this.score = i + 1;
    },
    saveScore() {
      const data = {
        evaluateScore: this.score,
        teacherId: this.lecturerData.teacherEvaluateDto.teacherId,
        id: this.lecturerData.teacherEvaluateDto.id,
      };
      this.$api.lecturer.saveScore({ data }).then(() => {
        this.sourceDialogVisible = false;
        this.getLectureInfo();
      });
    },
    goSource() {
      this.sourceDialogVisible = true;
      this.score= this.lecturerData?.teacherEvaluateDto?.evaluateScore || 0;
      console.log(111);
    },
    getLectureInfo() {
      const params = {
        ucode: this.$route.query.ucode,
      };
      this.$api.lecturer.stuFindByUcode({ params }).then((res) => {
        if (res.data) {
          this.lecturerData = res.data;
          this.score = res.data.teacherEvaluateDto.evaluateScore;
        }
      });
    },
    getCourseListByUcode() {
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ucode: this.$route.query.ucode,
      };
      this.$api.lecturer.getCourseListByUcode({ params }).then((res) => {
        if (res.data) {
          if (res.data.list && res.data.list.length) {
            this.noData = false;
          } else {
            this.noData = true;
          }
          this.AllCourseList = res.data.list || [];
          this.total = res.data.total;
        }
      });
    },
    handleDetail(item) {
      this.$router.push({
        path: "/course/detail",
        query: {
          ucode: item.ucode,
        },
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getCourseList();
    },
  },
};
</script>

<style lang="scss" src="../assets/css/list.scss" scoped></style>
